
// Fonts
//@import url("https://fonts.googleapis.com/css?family=Raleway:300,400,600");

// Variables
@import "variables";

// Bootstrap
@import "~bootstrap-sass/assets/stylesheets/bootstrap";

//@import "~bootstrap/dist/css/bootstrap.min.css";
@import "~select2/dist/css/select2.min.css";
@import "~select2-bootstrap-theme/dist/select2-bootstrap.min.css";
@import "~parsleyjs/src/parsley.css";
@import "~@fullcalendar/core/main.min.css";
@import "~@fullcalendar/daygrid/main.min.css";
@import "~@fullcalendar/list/main.min.css";
@import "~@fullcalendar/timegrid/main.min.css";
@import "~eonasdan-bootstrap-datetimepicker/build/css/bootstrap-datetimepicker.min.css";
@import "~datatables.net-bs/css/dataTables.bootstrap.min.css";
@import "~yadcf/jquery.dataTables.yadcf.css";
@import "~jquery-ui/themes/base/all.css";
//@import "~jquery-ui/themes/base/autocomplete.css";
@import "~spectrum-colorpicker/spectrum.css";
@import "~bootstrap-fileinput/css/fileinput.min.css";


// Toastr
@import "~toastr/build/toastr.min.css";

// My styles
@import "mystyles.scss";
@import "avatar.scss";
@import "select2.scss";



