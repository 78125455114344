.mydl dt, {
    display: inline-block;
    width: 150px;
}

.mydl dd {
    display: inline-block;
}

.my-required {
    color: black;
}

form {
    font-size: 13px;
}

.voter-image {
    width: 200px;
    height: 200px;
}

.mtop-10 dt {
    margin-top: 10px;
}

.my-hide {
    visibility: hidden;
}

#tinymce-wrapper, .mce-tinymce,.mce-stack-layout, .mce-edit-area{
    display: flex!important;
    flex-direction: column;
    flex: 1;
    align-items:stretch;
  }
  .mce-tinymce iframe{
    flex: 1;
  }


.tab-table {
    margin-top: 10px;
}

.yadcf-filter {
    width: 120px;
}

table {
    font-size: 12px;
}


.badge-danger {
    background-color: red;
}

tr.trash, table.trash td {
    color: red;
}

tr.secret, table.secret td, span.secret {
    color: blue;
    font-weight: bold;
}

.pad-bottom-10 {
    padding-bottom: 10px;
}

.ui-datepicker-inline {
    margin: auto;
}

.input-group-btn input {
    width:auto !important;
}

.select2-selection__rendered {
    margin-top: 0px !important;
}

#toast-container > div {
    opacity: 1;
}

// Toastr styles

.toast-start {
    background-color: rgb(238, 47, 255);
    
}

.toast-answered {
    background-color: rgb(10, 13, 177)
}

.toast-date {

}

.toast-callerno {


}

.toast-voters {

}

.toast-unknown {

}

.toast-title {

}

.sidebar-extra {
    margin-top: 50px;
}

.label-brown {
    background-color: #994d33;
}

.label-gray {
    background-color: #bfbfbf;
}

.label-magenta {
    background-color: #cc00cc;
}

.label-yellow {
    background-color: #ecf901;
    color: black;
    font-weight: bold;
    font-size: 11px;
}

.popup-z {
    position: absolute;
    left: 0px;
    top: 0px;
    margin-left: 50%;
    margin-top: 400px;
    z-index: 999;
    width: 42px;   
}

.mce-tinymce {
    height: 100% !important;
}

.highlight {
    background-color: #f6f9da !important;
}
