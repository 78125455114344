.kv-avatar .krajee-default.file-preview-frame,.kv-avatar .krajee-default.file-preview-frame:hover {
    margin: 0;
    padding: 0;
    border: none;
    box-shadow: none;
    text-align: center;
}
.kv-avatar {
    display: inline-block;
}
.kv-avatar .file-input {
    display: table-cell;
    width: 213px;
}
.kv-reqd {
    color: red;
    font-family: monospace;
    font-weight: normal;
}

.avatar-upload {
    width: 200px;
    height: 200px;
}

.avatar-upload img {
    max-width: 200px;
    max-height: 200px;
}

.avatar-upload input {
    cursor: pointer;
    opacity: 0;
    color: transparent;
}

.reset-avatar {
    padding-top: 5px;
}