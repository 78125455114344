.select2-container--bootstrap .select2-selection--multiple .select2-selection__choice {
    background-color: #367fa9;
    border-color: #367fa9;
    padding: 1px 10px;
    color: #fff ;
} 

.select2-container--bootstrap .select2-selection--multiple .select2-selection__choice__remove {
    margin-right: 5px;
    color: rgba(255,255,255,0.7);
}


.select2-container--bootstrap.select2-container--focus .select2-selection--multiple, .select2-container--bootstrap .select2-search--dropdown .select2-search__field {
    border-color: #3c8dbc !important;
}



.select2-container--bootstrap .select2-selection--multiple .select2-selection__rendered {
    box-sizing: border-box;
    list-style: none;
    margin: 0;
    padding: 0 5px;
    width: 100%;
}

.select2-container--bootstrap .select2-selection--multiple, .select2-selection .select2-selection--bootstrap {
    border: 1px solid #d2d6de;
}

.select2-container--default.select2-container--open {
    border-color: #3c8dbc;
}

.select2-container--bootstrap .select2-results__option[aria-selected=true], .select2-container--bootstrap .select2-results__option[aria-selected=true]:hover {
    color: #444;
}
.select2-container--bootstrap .select2-results__option[aria-selected=true] {
    background-color: #ddd;
}

/* .select2-container--bootstrap.select2-container--focus .select2-selection--multiple {
    border: solid black 1px;
    outline: 0;
}
 */
/* .select2-results__options {
    list-style: none;
    margin: 0;
    padding: 0;
}
 */
.select2-container--bootstrap .select2-results>.select2-results__options[aria-multiselectable=true] {
    border-top: 1px solid #3c8dbc;
}